<template>
  <section class="main">
    <questionViewWidget
      :tikuQuestion="tikuQuestion"
      :showAnswer="true"
      :showQuestion="true"
      :qIndex="1"
    ></questionViewWidget>
  </section>
</template>

<script>
import questionViewWidget from "@/component/questionViewWidget";
import { tikuApi } from "@/api/tikuApi";

export default {
  data() {
    return {
      showLatex: 0,
      subjectCourseId: "0",
      id: "0",
      tikuQuestion: {},
    };
  },
  components: { questionViewWidget },
  mounted() {
    console.log(process.env.VUE_APP_ENV);
    const id = this.$route.query.id;
    const subjectCourseId = this.$route.query.subjectCourseId;
    this.id = id;
    this.subjectCourseId = subjectCourseId;
    if (this.$route.query && this.$route.query.showLatex) {
      this.showLatex = this.$route.query.showLatex;
    }
    this.onGet();
  },
  methods: {
    onGet() {
      tikuApi.get(this.subjectCourseId, this.id).then((ret) => {
        let { code, data: item } = ret;
        if (code == 0 && item) {
          this.tikuQuestion = item;
          console.log("tikuQuestion", this.tikuQuestion);
        }
      });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.main {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 600px;
  text-align: left;
  .question {
    display: flex;
    flex-direction: column;
    img {
      vertical-align: middle;
    }
    .title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    .content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      p {
        margin: 0px;
      }
    }
  }
}
</style>

<style>
.latex-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.latex-inline {
  vertical-align: middle;
}
p {
  margin: 0;
}
</style>
